import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ElectronService } from '@app/providers/electron.service';
// import { Analytics, logEvent } from '@angular/fire/analytics';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfig } from 'environments/environment';
import { Injectable, inject } from '@angular/core';
import Analytics from 'electron-google-analytics4'; // esm
import { TimeConversionService } from './time-conversion.service';

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    timeService = inject(TimeConversionService);
    sessionStartTime: number = this.timeService.getUnix();
    isAgent = AppConfig.isAgent;
    url: string;
    computerName;
    // analytics = inject(Analytics);
    electronAnalytics = new Analytics(
        AppConfig.firebase.measurementId,
        AppConfig.googleAnalyticSecret
    );
    isElectron: boolean;
    started: boolean;

    constructor(
        private electronService: ElectronService,
        private translate: TranslateService,
        private router: Router
    ) {
        this.isElectron = this.electronService.isElectron();
        this.computerName = this.electronService.isElectron()
            ? this.electronService.getComputerName()
            : null;
    }

    createTracker() {
        if (!this.isElectron) {
            return;
        }

        this.router.events.subscribe((element) => {
            if (element instanceof NavigationEnd) {
                this.url = element.urlAfterRedirects;
                this.electronAnalytics.setParams({
                    page_title: AppConfig.pageName,
                    page_path: this.url,
                    computerName: this.computerName,
                    appLanguage: this.translate.currentLang,
                });

                this.electronAnalytics.event('page_view');
            }
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.electronAnalytics.setParams({
                page_title: AppConfig.pageName,
                page_path: this.url,
                computerName: this.computerName,
                appLanguage: this.translate.currentLang,
            });

            this.electronAnalytics.event('page_view');
        });
    }

    onCategoryMenuClick(menuName) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            value: 1,
            computerName: this.computerName,
        });
        this.electronAnalytics.event('open');
    }

    onPoiSelection(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: this.getPoiLabel(menuName, selectedItem),
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('selected');
    }

    onPlayButtonClick(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: this.getPoiLabel(menuName, selectedItem),
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('navigation');
    }

    onSendToMobileClick(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: this.getPoiLabel(menuName, selectedItem),
            value: 1,
            computerName: this.computerName,
        });
        this.electronAnalytics.event('showQrCode');
    }

    onStaticInformationMenuClick(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label:
                menuName == 'LAX TV'
                    ? selectedItem.categoryName
                    : selectedItem.category.en,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('categorySelected');
    }

    onStaticInformationSectionExpansion(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: selectedItem.title.en,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('sectionExpanded');
    }

    onVideoClick(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: selectedItem.videoTitle,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('videoPlay');
    }

    onSelfieCaptureButtonClick(menuName) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('capture');
    }

    onSelfieRecaptureButtonClick(menuName) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('recapture');
    }

    onSelfieReShareButtonClick(menuName) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('share');
    }

    onSelfieUnmaskButtonClick(menuName) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('unmaskCode');
    }

    onGlobalSearchClick() {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Search',
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('open');
    }

    onFlightSearchClick(menuName) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('search');
    }

    onSearchTermTyped(term) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_label: term,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('searchTerm');
    }

    onSearchResultClick(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: this.getPoiLabel(menuName, selectedItem),
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('searchSelected');
    }

    onStaticInformationSearchResultClick(menuName, label) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: label,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('searchSelected');
    }

    onSurveyOpen(surveyType) {
        if (!this.isElectron) {
            return;
        }
        let label =
            surveyType == 'persistentSurvey'
                ? 'Persistent Survey'
                : 'Post Call Survey';

        this.electronAnalytics.setParams({
            event_category: 'Survey',
            event_label: label,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('surveyOpened');
    }

    onSurveyAnswer(surveyAnswer) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Survey',
            event_label: surveyAnswer.question,
            value: surveyAnswer.response,
        });

        this.electronAnalytics.event('surveyAnswer');
    }

    onLoginFailed(agentEmail) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Agent Status',
            event_label: agentEmail,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('agentLoginFailed');
    }

    onLoginSuccess(agentEmail) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Agent Status',
            event_label: agentEmail,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('agentLoginSuccessful');
    }

    onLogOut(agentEmail) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Agent Status',
            event_label: agentEmail,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('agentLogout');
    }

    onAgentSetAvailable(agentEmail) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Agent Status',
            event_label: agentEmail,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('agentAvailable');
    }

    onAgentSetUnavailable(agentEmail) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Agent Status',
            event_label: agentEmail,
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('agentUnavailable');
    }

    onSendToClient(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: menuName,
            event_label: this.getPoiLabel(menuName, selectedItem),
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('sendToClient');
    }

    onCallRing() {
        if (!this.isElectron) {
            return;
        }
        let action = this.isAgent ? 'agent-incoming-call' : 'agent-ring';

        this.electronAnalytics.setParams({
            event_category: 'Live Help',
            value: 1,
            computerName: this.computerName,
        });

        this.electronAnalytics.event(action);
    }

    onCallPickup(callDuration, agentEmail?) {
        if (!this.isElectron) {
            return;
        }
        let label = this.isAgent
            ? `${agentEmail} Picked Up`
            : 'Agent Picked Up';

        this.electronAnalytics.setParams({
            event_category: 'Live Help',
            event_label: label,
            value: callDuration,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('agentPickup');
    }

    onCallNotAnswered(callDuration, agentEmail?) {
        if (!this.isElectron) {
            return;
        }
        let label = this.isAgent
            ? `${agentEmail} Did Not Pick Up`
            : 'Agent Did Not Pick Up';

        this.electronAnalytics.setParams({
            event_category: 'Live Help',
            event_label: label,
            value: callDuration,
            computerName: this.computerName,
        });

        this.electronAnalytics.event('agent-no-answer');
    }

    onCallTermination(callDuration) {
        if (!this.isElectron) {
            return;
        }
        let action = this.isAgent ? 'agent-terminated' : 'user-terminated';
        let label = this.isAgent ? 'Agent ended call' : 'User ended call';

        this.electronAnalytics.setParams({
            event_category: 'Live Help',
            event_label: label,
            value: callDuration,
            computerName: this.computerName,
        });

        this.electronAnalytics.event(action);
    }

    onStateChange(action, sessionDuration?) {
        if (!this.isElectron || (this.started && action === 'start')) {
            return;
        }

        this.started = true;

        let label = action == 'start' ? 'Session Started' : 'Session Ended';
        let value = action == 'start' ? 1 : sessionDuration;
        if (action == 'start')
            this.sessionStartTime = this.timeService.getUnix();

        if (action !== 'start') {
            this.started = false;
        }

        this.electronAnalytics.setParams({
            event_category: 'Kiosk Session',
            event_label: label,
            value: value,
            computerName: this.computerName,
        });
        this.electronAnalytics.event(action);
    }

    onCheckinSet(length) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'Check-in',
            event_label: 'Check-in',
            value: length,
            computerName: this.computerName,
        });
        this.electronAnalytics.event('refreshCheckinPOIs');
    }

    onAllPoisSet(length) {
        if (!this.isElectron) {
            return;
        }
        this.electronAnalytics.setParams({
            event_category: 'POIs',
            event_label: 'POIs',
            value: length,
            computerName: this.computerName,
        });
        this.electronAnalytics.event('refreshAllPOIs');
    }

    getPoiLabel(menuName, selectedItem) {
        if (!this.isElectron) {
            return;
        }
        let label = '';
        switch (menuName) {
            case 'Hotels':
            case 'tourism':
                label = selectedItem.name;
                break;
            case 'Flights':
                let flightType =
                    selectedItem.flightType == 'A' ? 'Arrival' : 'Departure';
                let regExp = /\(([^)]+)\)/; // get airportCode between parentheses
                let matches = regExp.exec(selectedItem.cityName);
                //matches[1] contains the value between the parentheses
                label = `${selectedItem.flightNumber} - ${flightType} - ${matches[1]}`;
                break;
            case 'LAX TV':
                label = `${menuName} - ${selectedItem.selectedMenu}`;
                break;
            case 'INFO & TIPS':
            case 'ADA':
                label = `${menuName} - ${selectedItem}`;
                break;
            default:
                label = `${selectedItem.name} ${selectedItem.details.en.fullLocation} - ${selectedItem.id}`;
                break;
        }

        return label;
    }
}
